<template>
  <b-container class="main-content-container px-0" fluid>
    <div class="pt-2 dataTables_wrapper VueTables no-shadow table-responsive">
      <div class="row no-gutters">
        <div class="col">
          <div class="px-4 row justify-content-between">
          <span style="font-size: 1.83em">
            List
          </span>
            <b-button variant="dark" :to="{name: 'import-previous-orders'}">Import</b-button>
          </div>
        </div>
        <div class="col col-lg-3">
          <div class="form-group form-inline pull-left VueTables__search">
            <div class="VueTables__search-field d-flex justify-content-between">
              <b-form-select v-model="client_id" @change="load">
                <b-form-select-option value="">Select a client</b-form-select-option>
                <b-form-select-option v-for="client of clientList" :value="client.id">{{ client.username }}
                </b-form-select-option>
              </b-form-select>
              <b-button variant="danger" class="ml-2" @click="deleteClientData(1)"
                        v-if="client_id"
                        v-tippy="{ placement : 'top',  arrow: true }" :content="'Delete all selected user data'"
              ><i class="material-icons">clear</i>
              </b-button>
              <b-form-input class="ml-2 VueTables__search__input form-control" v-model="query" placeholder="Search VIN"
                            @keyup.enter="paginate(1);load"/>
            </div>
          </div>
        </div>
      </div>
      <b-table small bordered hover class="dataTable" :items="tableData.tableData" :fields="fields">
        <template #cell(client)="{item}">
          {{ item.client.username }}
        </template>
        <template #cell(all_data)="{item}">
          <b-table small hover :items="[item.all_data]" class="dataTable" bordered></b-table>
        </template>
        <template #cell(options)="{item}">
          <b-button-group class="d-flex justify-content-center" size="small">
            <b-button
              v-tippy="{ placement : 'top',  arrow: true }" :content="'Delete'"
              class="btn btn-primary" @click="deleteItem(item)">
              <i class="material-icons">clear</i>
            </b-button>
          </b-button-group>
        </template>

      </b-table>
    </div>
    <div class="d-flex justify-content-between">
      <p class="justify-content-start pl-4 pt-3">
        Showing {{ tableData.from }} to {{ tableData.to }} of
        {{ tableData.total }} records</p>
      <b-pagination
        class="justify-content-end no-shadow p-2"
        :value="tableData.currentPage"
        :per-page="tableData.perPage"
        :total-rows="tableData.total"
        @change="paginate"
      />
    </div>
  </b-container>
</template>

<script>
import {Pagination} from "@/utils/pagination";
import {get, post} from "@/services/api";
import {UserRole} from "@/utils/auth.roles";
import show_alert_mixin from "@/utils/show_alert_mixin";

export default {
  name: "List",
  mixins: [show_alert_mixin],
  data: () => ({
    tableData: new Pagination(),
    query: '',
    client_id: '',
    fields: ['client', 'VIN', {
      key: 'price',
      label: 'Credits'
    }, 'date', 'all_data', 'options'],
    clientList: []
  }),
  methods: {
    load() {
      this.$store.commit('loaderManager', true);
      get(`previous-orders?page=${this.tableData.currentPage}&limit=${this.tableData.perPage}&query=${this.query}&client_id=${this.client_id}`, null, true)
        .then(({data}) => {
          this.tableData.total = data.count;
          this.tableData.setTableData(data.data);
        }).finally(() => this.$store.commit('loaderManager', false))
    },
    paginate(page) {
      this.tableData.currentPage = page;
      this.load();
    },
    deleteItem(item) {
      this.$swal({
        title: 'Are you sure ?',
        icon: 'question',
        showCancelButton: true,
        cancelButtonColor: '#000',
        confirmButtonColor: '#d14343'
      }).then(value => {
        if (value.isConfirmed) {
          this.$store.commit('loaderManager', true)
          post(`previous-orders/delete/${item._id}`, null, true)
            .then(() => this.load())
            .finally(() => this.$store.commit('loaderManager', false))
        }
      })
    },
    loadClients() {
      const roles = UserRole;
      get(`user/${roles.Dealer},${roles.SubDealer},${roles.Distributor}`, null, true)
        .then(({data}) => {
          this.clientList = data;
        })
    },
    deleteClientData(time = 1, message = 'Are you sure ?') {
      this.$swal({
        icon: 'question',
        title: message,
        showCancelButton: true,
        cancelButtonColor: '#000',
        confirmButtonColor: '#d14343',
        confirmButtonText: time > 1 ? 'I said yes' : 'Yes'
      }).then(value => {
        if (value.isConfirmed) {
          if (time === 1)
            this.deleteClientData(2, '100% sure ?')
          else
            post(`previous-orders/delete-by-client/${this.client_id}`, null, true)
              .then(() => {
                this.showAlertNotification('All client data has been deleted')
                this.load()
              })
        }
      })
    },
  },
  mounted() {
    this.loadClients();
    this.load();
  }
}
</script>

<style scoped>

</style>
